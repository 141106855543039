/* eslint-disable no-param-reassign */
import JSEncrypt from 'jsencrypt';

const resetForm = (form) => {
  const fieldsToEncrypt = form.querySelectorAll('[data-encrypt]');
  fieldsToEncrypt.forEach((field) => {
    field.name = field.dataset.previousName;
    field.nextElementSibling.remove();
  });
};

const encryptFields = (form) => {
  const encrypt = new JSEncrypt();
  const encryptKeyElement = document.getElementById('js-public_key');
  if (encryptKeyElement === null) return;

  const encryptKey = encryptKeyElement.dataset.value;
  encrypt.setKey(encryptKey);

  const fieldsToEncrypt = form.querySelectorAll('[data-encrypt]');

  fieldsToEncrypt.forEach((field) => {
    const encryptedValue = encrypt.encrypt(field.value);
    if (encryptedValue) {
      // create new hidden field with same name and encrypted value
      const newField = `<input type="hidden" name="${field.name}" value="${encryptedValue}">`;
      // remove name from field so its not submitted
      field.dataset.previousName = field.name;
      field.name = '';
      // insert new field on DOM
      field.insertAdjacentHTML('afterend', newField);
    }
  });
};

const encryptForm = (event) => {
  encryptFields(event.currentTarget);
};

const encryptFormOnSubmit = (form) => {
  form.addEventListener('submit', encryptForm);
};

const initEncryption = () => {
  const forms = document.querySelectorAll('form[data-form-encrypt]');
  forms.forEach(encryptFormOnSubmit);
};

window.encryptFormOnSubmit = encryptFormOnSubmit;
window.resetForm = resetForm;

document.addEventListener('turbolinks:load', initEncryption);

export { encryptFields, resetForm };
