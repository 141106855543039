/*
  Function to get reading time based on word count

  readingTime("text string")
*/

const readingTime = (text) => {
    const wpm = 235; // Words Per Minute
    const wordCount = text.trim().split(/\s+/g).length; // Words in callback
    // const readingTimeThreshold = 7000;
  
    // define words per second based on words per minute (wpm)
    const wordsPerSecond = wpm / 60;
  
    // define total reading time in seconds
    const totalReadingTimeSeconds = wordCount / wordsPerSecond;
  
    // define reading time
    const readingTimeMinutes = Math.ceil(totalReadingTimeSeconds / 60);
  
    return {
      minutes: readingTimeMinutes,
      seconds: totalReadingTimeSeconds
    };
  };
  
  export default readingTime;
  