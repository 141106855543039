/* eslint-disable object-shorthand */
import Rails from '@rails/ujs';

const resendSms = (url) => {
  Rails.ajax({
    url: url,
    type: 'POST',
  });
};

const initResendSms = () => {
  window.resendSms = resendSms;
};

export { initResendSms };
