/*
  Callback function
  Shows a info-box with a message

  params:
    message: MANDATORY;
    duration: time until dismiss, in miliseconds. default: readingTime(). 0 will be infinite;
    targetContainer: ;
    classToAdd: class added to box. default: '';
    dismiss: bolean to see if it's possible to dismiss or not. default: true;

  Example:
    confirmCallback('<i class="icon icon-check"></i> Agreement saved!', {
      duration: 0,
      targetContainer: document.querySelector('#layout-notifications'),
      classToAdd: 'bg-xs-green-100',
      dismiss: true
    })
*/

import randomizer from './randomizer';
import readingTime from './reading_time';

const confirmCallback = (
  message = undefined, {
    duration = undefined,
    targetContainer = document.querySelector('[data-callback-targetContainer]'),
    classToAdd = 'bg-grey-100',
    dismiss = true,
  },
) => {
  // message is MANDATORY
  if (message !== undefined) {
    // Generate a random ID
    const id = `C${randomizer()}`;

    // let timing = duration || readingTime(message);
    // Define timing. (above doesn't work when duration is 0 (zero))
    let timing = duration !== undefined ? duration : readingTime(message).seconds;

    // Create element as a DOM element, this way we can use some methods
    const $container = targetContainer;
    const newCallback = document.createElement('div');
    newCallback.className = `notification ${classToAdd}`;
    newCallback.setAttribute('role', 'alert');
    newCallback.dataset.callback = id;
    newCallback.style = 'opacity: 0';

    let messageTemplate = `<div class="${dismiss ? 'col-xs-10 col-md-8' : 'col-xs-12'}">
                             <p class="m-xs-0 font-xs-12">${message}</p>
                            </div>`;
    messageTemplate += dismiss ? `<div class="col-xs-2 col-md-4 text-xs-right middle-xs">
                                    <button aria-label="Dismiss this notification" data-callback-dismiss="${id}">
                                      <i class="icon icon-error font-xs-10" aria-hidden="true"></i>
                                    </button>
                                  </div>` : '';
    // Prevent notifications from appending on to one another
    $container.innerHTML = '';
    // Append element to the page
    targetContainer.insertAdjacentElement('beforeend', newCallback);
    newCallback.insertAdjacentHTML('beforeend', messageTemplate);

    // Animation handlers
    newCallback.addEventListener('animationend', () => {
      if (newCallback.classList.contains('fade-in')) {
        newCallback.style = 'opacity: 1';
        newCallback.classList.remove('fade-in');
      }

      if (newCallback.classList.contains('fade-out')) {
        newCallback.style = 'opacity: 0';
        newCallback.remove();
      }
    });

    newCallback.classList.add('fade-in');

    // If timing == 0 then we only remove if the user clicks to remove
    if (timing !== 0) {
      if (timing < 7) timing = 7;
      let isHover = false;

      newCallback.addEventListener('mouseenter', () => { isHover = true; });
      newCallback.addEventListener('mouseleave', () => { isHover = false; });

      setTimeout(() => {
        if (isHover) {
          newCallback.addEventListener('mouseleave', () => {
            setTimeout(() => {
              newCallback.classList.add('fade-out');
            }, 500);
          });
        } else {
          newCallback.classList.add('fade-out');
        }
      }, timing * 1000);
    }

    // if it has the dismiss action, we can click to remove
    if (dismiss) {
      newCallback.querySelector('[data-callback-dismiss]').addEventListener('click', (event) => {
        event.currentTarget.closest('[data-callback]').classList.add('fade-out');
      });
    }
  }
};

export default confirmCallback;
window.confirmCallback = confirmCallback;
