import Rails from '@rails/ujs';
import MicroModal from 'micromodal';
import checkCountryISOCode from './language_iso_codes';
import randomizer from './randomizer';

/*
  # USAGE #

  ## Passing an element ##
  confirmModal(<element>)

  ## Building with parameters ##
  confirmModal('en', {
    action: '/settings/adviser/profile',   // Optional
    method: 'GET',  // Optional: GET (default) | POST | UPDATE | DELETE (Won’t be used if the action above is null)
    isTrue: () => { ...function here }
  })

  # EXPLANATION #
  data-confirm-modal:
  - By default the function will initialize itself if this attribute exists
  - We also use this attribute to pass the locale, if nothing is passed it will use 'en' as default

  data-confirm-modal-type:
  - Will be the 'path' of the translation

  data-confirm-modal-action:
  - This holds the action|url of what we want it to do if clicked
  - It's also possible to also use the current HREF attribute instead

  data-confirm-modal-method:
  - Method type of the action (optional)
  - "GET" will be the default, other options: POST | UPDATE | DELETE


  # EXAMPLES #

  ## Link ##
  <a data-confirm-modal="en" data-confirm-type="confirmations.default" data-confirm-modal-action="/settings/adviser/profile">Unlock</a>

  ## Toggle ##
  <div class="toggle">
    <input type="checkbox" id="toggle-adviser-lp" name="toggle-adviser-lp" data-confirm-modal="en" data-confirm-modal-type="confirmations.default" data-confirm-modal-action="/settings/adviser/profile">
    <label for="toggle-adviser-lp" class="label">
      <span class="tick-box"></span>Publish
    </label>
  </div>
*/

const confirmModal = (trigger = 'en', {
  action: optionsAction = null,
  method: optionsMethod = null,
  isTrue: optionsCallback = null
} = {}) => {
  const csrfToken = document.head.querySelector('meta[name="csrf-token"]').content;
  const rndID = randomizer() // We use this to generate a random ID for the modal

  let locale = trigger.dataset.confirmModal || 'en';
  let translationPath = trigger.dataset.confirmModalType || 'confirmations.default';
  let modalTitle = `${translationPath}.title`;
  let modalContent = `${translationPath}.content`;
  let modalCancel = `${translationPath}.cancel`;
  let modalOk = `${translationPath}.ok`;
  let modalAction;
  let modalMethod;

  let urlParams = '';
  const _indexArr = [modalTitle, modalContent, modalCancel, modalOk];
  _indexArr.forEach((value) => {
    urlParams += `indexes[]=${value}&`;
  });

  if (typeof trigger === 'object') {
    // In case we are using a toggle (checkbox/radio button) we need to change the elem to
    // target the input instead, because the input will have the data attributes
    const elem = trigger.tagName == 'LABEL' ? document.querySelector(`#${trigger.getAttribute('for')}`) : trigger;

    modalAction = elem.hasAttribute('href') && elem.attributes['href'].value !== '#' ? elem.attributes['href'].value : elem.dataset.confirmModalAction;
    modalMethod = elem.dataset.confirmModalMethod || 'GET';
  }

  // When we want to trigger the confirmatiom modals without the dependency of data attributes
  if (typeof trigger === 'string' && checkCountryISOCode(trigger)) {
    locale = trigger;

    modalAction = optionsAction || '#';
    modalMethod = optionsAction !== '#' ? optionsMethod : null;
  }

  // Get the translations using Advicefront's translation service
  fetch(`/translations?${urlParams}locale=${locale}`, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': csrfToken,
      'accept': 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'same-origin'
  })
    .then(response => response.json())
    .then((data) => {
      const translated = data.translated_strings;
      const translatedTitle = translated[modalTitle];
      const translatedContent = translated[modalContent];
      const translatedButtonCancel = translated[modalCancel];
      const translatedButtonOk = translated[modalOk];

      generateModal(rndID, translatedTitle, translatedContent, translatedButtonCancel, translatedButtonOk, modalAction, modalMethod, trigger, optionsCallback);

    })
}


const generateModal = (id, modalTitle, modalContent, modalButtonCancel, modalButtonOk, modalAction = '#', modalMethod = null, triggeredElem = null, callback) => {
  const dataMethod = modalMethod !== null ? 'data-method="' + modalMethod.toUpperCase() + '"' : '';
  const template =
  `<div class="modal micromodal-slide" id="M${id}" aria-hidden="true">
    <div class="modal-overlay" tabindex="-1" data-micromodal-close>
      <section class="modal-container" role="dialog" aria-modal="true" aria-labelledby="MT${id}">
        <header class="row middle-xs p-xs-2 bg-xs-grey-100">
          <div class="col-xs-8">
            <h1 class="font-xs-18 font-md-22 font-xs-light text-xs-grey-900 m-xs-y-0" id="MT${id}">${modalTitle}</h1>
          </div>
          <div class="col-xs-4 text-xs-right">
            <button class="modal-close" aria-label="Close modal" data-micromodal-close></button>
          </div>
        </header>
        <main class="p-xs-2 text-xs-center" id="MC${id}">
          <p>${modalContent}</p>
        </main>
        <footer class="row p-md-x-2 p-md-y-1-5 center-md">
          <div class="col-xs-12 col-md last-xs first-md">
            <button class="btn btn-md-border-secondary-light m-md-right-1" data-micromodal-close>${modalButtonCancel}</button>
          </div>
          <div class="col-xs-12 col-md">
            <a href="${modalAction}" class="btn ${modalMethod !== null && modalMethod.toUpperCase() == 'DELETE' ? 'btn-xs-bg-red-500' : 'btn-xs-bg-primary-light'}" ${modalAction !== null && modalAction !== '#' ? 'data-remote="true"' : ''} ${dataMethod} data-confirm-modal-ok>${modalButtonOk}</a>
          </div>
        </footer>
      </section>
    </div>
  </div>`;

  // Insert modal template into the DOM
  document.querySelector('body').insertAdjacentHTML('beforeend', template);

  // Attach needed events
  // confirmationModalEvents(`M${id}`, triggeredElem, callback);

  // Trigger modal
  MicroModal.show(`M${id}`, {
    onClose: modal => modal.remove(),
    onShow: modal => confirmationModalEvents(modal.id, triggeredElem, callback),
    disableScroll: true,
    awaitOpenAnimation: true,
    awaitCloseAnimation: true
  });
}


const confirmationModalEvents = (modalID, triggeredElem = null, callback = null) => {
  const targetConfirmModal = document.getElementById(modalID);

  targetConfirmModal.addEventListener('click', (event) => {

    if (event.target.closest('[data-confirm-modal-ok]')) {
      event.preventDefault();

      if (typeof triggeredElem.tagName == 'INPUT') {
        switch (triggeredElem.attributes.type.value) {
          case 'checkbox':
            triggeredElem.checked ? triggeredElem.checked = false : triggeredElem.checked = true;
            if (triggeredElem.closest('form') != null) {
              Rails.fire(triggeredElem.closest('form'), 'submit');
            }
            break;
          case 'submit':
            Rails.fire(triggeredElem.closest('form'), 'submit');
            break;
        }
      }

      // Close and remove confirmation modal
      MicroModal.close(modalID);

      // Run the JS code passed on the callback
      if (typeof callback == 'function') {
        return callback();
      }
    }

  });
}


// Inits the generateModal
document.addEventListener('click', (event) => {
  if (event.target.closest('[data-confirm-modal]')) {
    event.preventDefault();
    confirmModal(event.target.closest('[data-confirm-modal]'));
  }
})

export default confirmModal;
window.confirmModal = confirmModal; // In case we need to access this funcion outside Webpack
